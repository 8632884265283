// components/CustomAccordion.tsx
import React, { useState } from 'react';
import { Text } from '@itandi/itandi-bb-ui';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import Styles from './index.module.scss';

type CustomAccordionProps = {
  title: string;
  children: React.ReactNode;
};

export const CustomAccordion: React.FC<DeepReadonly<CustomAccordionProps>> = ({
  title,
  children
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleAccordion = (): void => {
    setExpanded(!expanded);
  };

  return (
    <div className={Styles.accordion}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={Styles.accordionHeader} onClick={toggleAccordion}>
        <Text size="S">{title}</Text>
        {expanded ? (
          <RiArrowUpSLine size={24} />
        ) : (
          <RiArrowDownSLine size={24} />
        )}
      </div>
      {expanded && <div className={Styles.accordionContent}>{children}</div>}
    </div>
  );
};
