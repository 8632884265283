import React, { useState, useEffect } from 'react';

export const useFeatureFlag = (
  flagName: string,
  defaultValue = false
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [flag, setFlag] = useState<boolean>(() => {
    const storedFlag = localStorage.getItem(flagName);
    if (storedFlag !== null) {
      try {
        const parsedFlag = JSON.parse(storedFlag);
        return typeof parsedFlag === 'boolean' ? parsedFlag : defaultValue;
      } catch {
        return defaultValue;
      }
    }
    return defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(flagName, JSON.stringify(flag));
  }, [flag, flagName]);

  return [flag, setFlag];
};
