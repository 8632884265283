import React from 'react';
import { Toggle, Text } from '@itandi/itandi-bb-ui';
import { CustomAccordion } from '~/components/common/Accordion';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

export const ExperimentalFeatureToggle: React.FC = () => {
  const [useExperimentalOwnerEditor, setUseExperimentalOwnerEditor] =
    useFeatureFlag('useExperimentalOwnerEditor');

  const handleToggleChange = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    event: Readonly<React.ChangeEvent<HTMLInputElement>>
  ): void => {
    setUseExperimentalOwnerEditor(event.target.checked);
  };

  return (
    <CustomAccordion title="その他の設定">
      <span>
        <Toggle
          checked={useExperimentalOwnerEditor}
          onChange={handleToggleChange}
        />
      </span>
      <Text display="Inline" size="S">
        実験的な家主編集画面を使用する
      </Text>
    </CustomAccordion>
  );
};
