import React from 'react';
import { RiDraftLine } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { Button } from '@itandi/itandi-bb-ui';
import { PATH as CONTRACT_RENEWAL_PATH } from '~/pages/contracts/[id]/renewal';
import { PATH as CONTRACTS_PATH } from '~/pages/contracts';
import { updateArchive, updateUnarchive } from '~/utils/api/resources/contract';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { ListCard } from '~/components/common/List/Card';
import { KeyedMutator } from 'swr';
import { ContractWithOwners } from '~/models/contract';
import Styles from './index.module.scss';

type Props = Readonly<{
  archived: boolean;
  mutate: KeyedMutator<ContractWithOwners> | undefined;
}>;

export const ContractsProcessContents: React.FC<Props> = ({
  archived,
  mutate
}: Props) => {
  const router = useRouter();
  const { id } = router.query;
  const updateArchived = async (): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('本当に解約しても良いですか？')) {
      const response = await updateArchive(Number(id));
      if (isSuccess(response)) {
        mutate?.();
        router.push(CONTRACTS_PATH);
        toast.success('解約しました');
        return;
      }
      toast.error(`解約に失敗しました: ${response.message}`);
    }
  };
  const updateUnarchived = async (): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('本当に解約を取り消しても良いですか？')) {
      const response = await updateUnarchive(Number(id));
      if (isSuccess(response)) {
        mutate?.();
        router.push(CONTRACTS_PATH);
        toast.success('解約を取り消しました。');
        return;
      }
      toast.error(`解約の取り消しに失敗しました: ${response.message}`);
    }
  };

  return (
    <ListCard
      icon={<RiDraftLine />}
      title={archived ? '解約の取り消し' : '更新・解約手続き'}
    >
      <div className={Styles.ButtonArea}>
        {archived ? (
          <Button
            onClick={(): Promise<void> => updateUnarchived()}
            variant="primary"
          >
            契約の解約を取り消す
          </Button>
        ) : (
          <>
            <Button
              onClick={(): void => {
                router.push(CONTRACT_RENEWAL_PATH(Number(id)));
              }}
              variant="primary"
            >
              契約の更新を行う
            </Button>
            <Button
              onClick={(): Promise<void> => updateArchived()}
              variant="primary"
            >
              契約の解約を行う
            </Button>
          </>
        )}
      </div>
    </ListCard>
  );
};
