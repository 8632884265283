import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { Input, InputSelect, Tooltip, SubHeadLine } from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

export const OwnersEditorBlockContact: React.FC = () => {
  const { register, formState } = useFormContext<OwnerForEdit>();
  const { errors } = formState;
  return (
    <FormBlock>
      <div className={Styles.Title}>
        <SubHeadLine title="連絡先情報" />
      </div>
      <FormBlockRow errorMessage={errors.tel1?.message} label="電話番号1">
        <Input
          placeholder="例）090-0000-0000"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('tel1')}
        />
        <div className={Styles.Description}>
          メインの連絡先として利用されます。
        </div>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.tel2?.message} label="電話番号2">
        <Input
          placeholder="例）03-0000-0000"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('tel2')}
        />
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.tel3?.message} label="電話番号3">
        <Input
          placeholder="例）06-0000-0000"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('tel3')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.email1?.message}
        label="メールアドレス1"
      >
        <Input
          placeholder="例）example@example.com"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email1')}
        />
        <div className={Styles.Description}>
          メインの連絡先として利用されます。
        </div>
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.email2?.message}
        label="メールアドレス2"
      >
        <Input
          placeholder="例）example@example.com"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email2')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.email3?.message}
        label="メールアドレス3"
      >
        <Input
          placeholder="例）example@example.com"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email3')}
        />
      </FormBlockRow>

      <FormBlockRow label="送付先の住所">
        <div className={Styles.ShippingAddressWrapper}>
          <InputSelect
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('shippingAddressIndex')}
          >
            <option value="">選択なし</option>
            <option value="1">住所1</option>
            <option value="2">住所2</option>
            <option value="3">住所3</option>
          </InputSelect>
          <Tooltip>
            「選択なし」かつ住所1が入力されている場合は、住所1が送付先の住所となります。
          </Tooltip>
        </div>
      </FormBlockRow>

      <FormBlockRow label="住所1">
        <div className={Styles.ZipcodeInputWrapper}>
          <span className={Styles.AddressLabel}>郵便番号</span>
          <Input
            placeholder="例）100-0001"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('address1Zipcode')}
          />
          {errors.address1Zipcode != null && (
            <div className={Styles.ErrorMessage}>
              {errors.address1Zipcode.message}
            </div>
          )}
        </div>
        <div className={Styles.AddressFirstRow}>
          <div className={Styles.AddressLeftInput}>
            <span className={Styles.AddressLabel}>都道府県</span>
            <Input
              placeholder="例）東京都"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1State')}
            />
            {errors.address1State != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1State.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>市区町村</span>
            <Input
              placeholder="例）千代田区"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1City')}
            />
            {errors.address1City != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1City.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressSecondRow}>
          <div className={Styles.AddressLeftInput}>
            <span className={Styles.AddressLabel}>番地・丁目</span>
            <Input
              placeholder="例）千代田1"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1Street')}
            />
            {errors.address1Street != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1Street.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>建物名・部屋番号</span>
            <Input
              placeholder="例）サンプルマンション101"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1Other')}
            />
            {errors.address1Other != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1Other.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressThirdRow}>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>備考</span>
            <Input
              placeholder="例）備考です。"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address1Note')}
            />
            {errors.address1Note != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address1Note.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>

      <FormBlockRow label="住所2">
        <div className={Styles.ZipcodeInputWrapper}>
          <span className={Styles.AddressLabel}>郵便番号</span>
          <Input
            placeholder="例）100-0001"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('address2Zipcode')}
          />
          {errors.address2Zipcode != null && (
            <div className={Styles.ErrorMessage}>
              {errors.address2Zipcode.message}
            </div>
          )}
        </div>
        <div className={Styles.AddressFirstRow}>
          <div className={Styles.AddressLeftInput}>
            <span className={Styles.AddressLabel}>都道府県</span>
            <Input
              placeholder="例）東京都"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2State')}
            />
            {errors.address2State != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2State.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>市区町村</span>
            <Input
              placeholder="例）千代田区"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2City')}
            />
            {errors.address2City != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2City.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressSecondRow}>
          <div className={Styles.AddressLeftInput}>
            <span className={Styles.AddressLabel}>番地・丁目</span>
            <Input
              placeholder="例）千代田1"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2Street')}
            />
            {errors.address2Street != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2Street.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>建物名・部屋番号</span>
            <Input
              placeholder="例）サンプルマンション101"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2Other')}
            />
            {errors.address2Other != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2Other.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressThirdRow}>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>備考</span>
            <Input
              placeholder="例）備考です。"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address2Note')}
            />
            {errors.address2Note != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address2Note.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>

      <FormBlockRow label="住所3">
        <div className={Styles.ZipcodeInputWrapper}>
          <span className={Styles.AddressLabel}>郵便番号</span>
          <Input
            placeholder="例）100-0001"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('address3Zipcode')}
          />
          {errors.address3Zipcode != null && (
            <div className={Styles.ErrorMessage}>
              {errors.address3Zipcode.message}
            </div>
          )}
        </div>
        <div className={Styles.AddressFirstRow}>
          <div className={Styles.AddressLeftInput}>
            <span className={Styles.AddressLabel}>都道府県</span>
            <Input
              placeholder="例）東京都"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3State')}
            />
            {errors.address3State != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3State.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>市区町村</span>
            <Input
              placeholder="例）千代田区"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3City')}
            />
            {errors.address3City != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3City.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.AddressSecondRow}>
          <div className={Styles.AddressLeftInput}>
            <span className={Styles.AddressLabel}>番地・丁目</span>
            <Input
              placeholder="例）千代田1"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3Street')}
            />
            {errors.address3Street != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3Street.message}
              </div>
            )}
          </div>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>建物名・部屋番号</span>
            <Input
              placeholder="例）サンプルマンション101"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3Other')}
            />
            {errors.address3Other != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3Other.message}
              </div>
            )}
          </div>
        </div>

        <div className={Styles.AddressThirdRow}>
          <div className={Styles.AddressRightInput}>
            <span className={Styles.AddressLabel}>備考</span>
            <Input
              placeholder="例）備考です。"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('address3Note')}
            />
            {errors.address3Note != null && (
              <div className={Styles.ErrorMessage}>
                {errors.address3Note.message}
              </div>
            )}
          </div>
        </div>
      </FormBlockRow>
    </FormBlock>
  );
};
