import React from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { RiAddBoxLine } from 'react-icons/ri';
import { InputSelect, Input, Button, SubHeadLine } from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import Styles from '../index.module.scss';

export const OwnersEditorBlockBankAccount: React.FC = () => {
  const { control, register, formState } = useFormContext<OwnerForEdit>();
  const { errors } = formState;

  const { fields, append } = useFieldArray<OwnerForEdit>({
    control,
    name: 'bankAccounts'
  });

  if (fields.length === 0) {
    append(
      {
        bankCode: '',
        branchCode: '',
        depositKind: '',
        accountNumber: '',
        accountHolder: '',
        remittanceFeeSettingId: ''
      },
      { shouldFocus: false }
    );
  }

  const handleAddOption = (): void => {
    append(
      {
        bankCode: '',
        branchCode: '',
        depositKind: '',
        accountNumber: '',
        accountHolder: '',
        remittanceFeeSettingId: ''
      },
      { shouldFocus: false }
    );
  };

  return (
    <FormBlock>
      <div className={Styles.Title}>
        <SubHeadLine title="銀行口座" />
      </div>
      {/* eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types */}
      {fields.map((field, index) => (
        <FormBlockRow
          key={field.id}
          errorMessage={errors.bankAccounts?.[index]?.message}
          label={`口座${index + 1}`}
        >
          <div className={Styles.BankAccountInnerBlock}>
            <InputSelect
              fullWidth
              isInvalid={!!errors.bankAccounts?.[index]?.bankCode}
              placeholder="金融機関を選択"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`bankAccounts.${index}.bankCode` as const)}
            >
              <option value="">選択なし</option>
              <option value="bank1">○○銀行</option>
              <option value="bank2">△△銀行</option>
              <option value="bank3">□□銀行</option>
            </InputSelect>
          </div>
          <div className={Styles.BankAccountInnerBlock}>
            <InputSelect
              fullWidth
              isInvalid={!!errors.bankAccounts?.[index]?.branchCode}
              placeholder="支店を選択"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`bankAccounts.${index}.branchCode` as const)}
            >
              <option value="">選択なし</option>
              <option value="branch1">○○支店</option>
              <option value="branch2">△△支店</option>
              <option value="branch3">□□支店</option>
            </InputSelect>
          </div>
          <div className={Styles.DepositRow}>
            <div className={Styles.BankAccountInnerBlock}>
              <div className={Styles.DepositLeftInput}>
                <InputSelect
                  isInvalid={!!errors.bankAccounts?.[index]?.depositKind}
                  placeholder="種類を選択"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register(`bankAccounts.${index}.depositKind` as const)}
                >
                  <option value="">選択なし</option>
                  <option value="resident">普通口座</option>
                  <option value="lessee">当座預金</option>
                  <option value="joint_guarantor">貯蓄預金</option>
                </InputSelect>
              </div>
            </div>
            <div className={Styles.DepositRightInput}>
              <Input
                isInvalid={!!errors.bankAccounts?.[index]?.accountNumber}
                placeholder="例） 0000001"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register(`bankAccounts.${index}.accountNumber` as const)}
              />
            </div>
          </div>
          <div className={Styles.BankAccountInnerBlock}>
            <Input
              isInvalid={!!errors.bankAccounts?.[index]?.accountHolder}
              placeholder="ｲﾀﾝｼﾞ ﾀﾛｳ"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(`bankAccounts.${index}.accountHolder` as const)}
            />
            <div className={Styles.Description}>
              1文字以上、カタカナ、半角数字(0~9)、半角英字大文字(A~Z)、記号 ().-
            </div>
          </div>
          <div className={Styles.BankAccountLastInnerBlock}>
            <InputSelect
              color="#000"
              fullWidth
              isInvalid={!!errors.bankAccounts?.[index]?.remittanceFeeSettingId}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(
                `bankAccounts.${index}.remittanceFeeSettingId` as const
              )}
            >
              <option value="">選択なし</option>
              <option value="remitance_fee1">手数料 1</option>
              <option value="remitance_fee2">手数料 2</option>
              <option value="remitance_fee3">手数料 3</option>
            </InputSelect>
          </div>
        </FormBlockRow>
      ))}

      <FormBlockRow label={`口座${fields.length + 1}`}>
        <span className={Styles.CreateButton}>
          <Button
            height="small"
            onClick={handleAddOption}
            startIcon={<RiAddBoxLine className={Styles.CreateIcon} />}
            variant="secondary"
          >
            追加
          </Button>
        </span>
      </FormBlockRow>
    </FormBlock>
  );
};
